import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'gatsby'
import getDeviceType from 'hooks/device-type'
import Cuub from 'svg/cuub'
import BouncingLine from 'templates/layout/bouncing-line'
import DesktopMenu from 'templates/layout/desktop/menu'
import MobileMenu from 'templates/layout/mobile/menu'
import GlobalIncludes from 'templates/layout/global-includes'
import SlideScroller from './slide-scroller'

// @refresh reset

export default function HomePageTpl(dataProps) {
	useEffect(() => {
		document.body.classList.add('bg-black')

		// Brute attempt to override Gatsby's scroll restoration mechanism leading to
		// the cube being scrolled into view each reload. Let's see if it bytes the ass somewhere
		window.scrollTo(0, 0)

		return () => document.body.classList.remove('bg-black')
	}, [])

	const deviceType = getDeviceType()
	const [logoClass, setLogoClass] = useState('text-white')
	const [quoteVariant, setQuoteVariant] = useState('white')
	const [menuTextClass, setMenuTextClass] = useState('text-white')
	const [menuBgClass, setMenuBgClass] = useState('bg-transparent')
	const [lineVisible, setLineVisible] = useState(true)

	const stickyVariantSetter = useCallback((element, enterVariant, exitVariant) => {
		let setter

		switch (element) {
			case 'logo':
				setter = setLogoClass
				break
			case 'quote':
				setter = setQuoteVariant
				break
			case 'menu-text':
				setter = setMenuTextClass
				break
			case 'menu-bg':
				setter = setMenuBgClass
				break
			case 'line':
				setter = setLineVisible
				break
			default:
				throw new Error('Unknown element kind')
		}

		setter((prevVariant) =>
			prevVariant === enterVariant ? exitVariant : enterVariant
		)
	}, [])

	const menuProps = {
		expectHero: false,
		bgClass: menuBgClass,
		colorClass: menuTextClass,
	}

	const slideScrollerEl = useMemo(
		() => <SlideScroller {...dataProps} stickyVariantSetter={stickyVariantSetter} />,
		[dataProps, stickyVariantSetter]
	)

	return (
		<>
			<DesktopMenu {...menuProps} />
			<MobileMenu {...menuProps} />
			{lineVisible ? <BouncingLine isFixed /> : null}
			{slideScrollerEl}
			{deviceType === 'desktop' && quoteVariant !== 'hidden' && (
				<StickyPanel logoClass={logoClass} quoteVariant={quoteVariant} />
			)}
			{deviceType === 'mobile' && quoteVariant !== 'hidden' && (
				<>
					<MobileCuubLogo logoClass={logoClass} />
					<MobileQuoteButton quoteVariant={quoteVariant} />
				</>
			)}
			<GlobalIncludes />
		</>
	)
}

const StickyPanel = ({ logoClass, quoteVariant }) => (
	<div className="pointer-events-none sticky bottom-screen-step z-10 hidden w-full items-center justify-between px-screen-step xl:flex">
		<div className="pointer-events-auto w-content-step-3">
			<Link
				to="/"
				className={`${logoClass} ${
					quoteVariant === 'total-black' ? 'hover:text-white' : 'accent-hover'
				} block transition-colors duration-500`}
			>
				<Cuub />
			</Link>
		</div>
		<div className="pointer-events-auto h-content-step w-content-step-4 text-[0.8rem]">
			<QuoteButton quoteVariant={quoteVariant} />
		</div>
	</div>
)

const QuoteButton = ({ quoteVariant }) => (
	<Link
		to="/brief/new/"
		className={`group relative block h-full w-full overflow-hidden uppercase tracking-px ${
			quoteVariant === 'white' ? 'text-white' : 'text-black'
		} transition-colors duration-500`}
	>
		<div
			key="first"
			className={`pointer-events-none absolute top-0 left-full h-full w-full ${
				quoteVariant === 'total-black' ? 'bg-black' : 'bg-accent'
			} transition-transform duration-300 ease-in group-hover:-translate-x-full`}
		/>
		<div
			key="second"
			className="pointer-events-none absolute top-0 left-0 flex h-full w-full items-center justify-between pl-4"
		>
			<div className="flex-initial whitespace-nowrap font-semibold leading-tight transition-colors duration-500 group-hover:text-white">
				Get a Quote
			</div>
			<div className="mr-4 flex h-full w-8 items-center justify-end">
				<i
					className={`block h-3 w-3 ${
						quoteVariant === 'total-black' ? 'bg-black' : 'bg-accent'
					} transition-colors duration-100`}
				/>
			</div>
		</div>
	</Link>
)

function MobileQuoteButton({ quoteVariant }) {
	return (
		<div className="quote-link-fixed fixed z-10 lg:static lg:hidden">
			<Link
				id="get-a-quote-mobile"
				to="/brief/new/"
				className={`group flex h-10 items-center justify-between overflow-hidden bg-transparent px-3 text-xs font-bold uppercase tracking-wider text-white ${
					quoteVariant === 'white' ? 'text-white' : 'text-black'
				} transition-colors duration-300`}
			>
				<i
					className={`block h-3 w-3 ${
						quoteVariant === 'total-black' ? 'bg-black' : 'bg-accent'
					}`}
				/>
				Get a Quote
			</Link>
		</div>
	)
}

function MobileCuubLogo({ logoClass }) {
	return (
		<Link
			to="/"
			className={`fixed left-[var(--screen-grid-step)] bottom-[var(--screen-grid-step)] w-[calc(4*var(--screen-grid-step))] max-w-[7.5rem] lg:hidden ${logoClass} z-20 transition-colors duration-300`}
		>
			<Cuub />
		</Link>
	)
}
